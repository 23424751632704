import PdfViewer from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import pdfClient from "../../http-common.js";
import dataServices from '../../services/data';

export default {
  name: 'BookReader',

  components: {
    PdfViewer
  },
  props: ["url", "book", "bookID"],
  data() {
    return {
      config: {
        withCredentials: true,
        requestHeaders: { 'Access-Control-Allow-Origin': '*' },
        sidebar: false,
        secondaryToolbar: {
          secondaryPresentationMode: true,
          secondaryOpenFile: true,
          secondaryPrint: true,
          secondaryDownload: false,
          secondaryViewBookmark: true,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: true,
          spreadOdd: true,
          spreadEven: true,
          documentProperties: true,
        },
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: true,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,

      },
      name: '', //change which pdf file loads
      path: 'lib/pdfjs-2.8.335-dist/web/viewer.html',
      spinner: false,
      currentBookId: null,
      pdf: null
    }
  },
  beforeMount() {
    this.$store.dispatch('setSpinner', true);
  },
  async mounted() {
    try {
      this.currentBookId = this.$root.$route.params.book ? this.$root.$route.params.book : undefined;
      if (this.$root.$route.params.url) {
        this.name = this.$root.$route.params.url;
        localStorage['bookReaderUrl'] = this.$root.$route.params.url;
      } else {
        var bookReaderUrl = localStorage['bookReaderUrl'];
        if (bookReaderUrl) this.name = bookReaderUrl;
      }
      this.pdf = await getFile(this.name);

    } catch (error) {
      console.log(error);
      alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el soporte de la biblioteca.")
    } finally {
      this.$store.dispatch('setSpinner', false);
    }
  },
  computed: {

  },
  methods: {
    afterCreatedHandler(pdfApp) {
      this.pdfApp = pdfApp;
    },
    pagesRenderedHandler(pdfApp) {
      let percentage = pdfApp.page/pdfApp.pagesCount * 100;
      setTimeout(() => {
        dataServices.saveUserPositions(this.currentBookId, "pdf", pdfApp.page, percentage.toFixed(2));
      });
    },
    async openHandler(pdfApp) {
      pdfApp.eventBus.on('pagerendered', this.eventPageRendered);

      this.info = [];
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console));

      if (!info) return;
      const props = Object.keys(info.info);
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop]
        };
        this.info.push(obj);
      });

    },
    eventPageRendered() {
      let percentage = this.pdfApp.page/this.pdfApp.pagesCount * 100;
      dataServices.saveUserPositions(this.currentBookId, "pdf", this.pdfApp.page, percentage.toFixed(2));
    }
  }
}

async function getFile(name) {
  return pdfClient.get(name, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    }
  }).then((response) => {
    return response.data;
  });

}
